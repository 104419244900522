import React from 'react';

import { Helmet } from 'react-helmet';

const NotFoundPage = () => {
  if (typeof window !== 'undefined') window.location = '/';
  return (
    <Helmet>
      <meta content='noindex' name='robots' />
    </Helmet>
  );
};
export default NotFoundPage;
